/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family:Verdana, Geneva, Tahoma, sans-serif!important; }

/* styles.css or your component-specific CSS file */
button:disabled {
  border-color: gray!important; /* Change to the desired color */
}

.container-fluid{
  min-height: 100%;
}

@media(max-width: 768px){
  .container-fluid{
    min-height: 100%;
    padding:0
  }
  body{
    overflow-x: hidden;
  }
}

.table-responsive table{
  margin-bottom: 10vh!important;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


/* For filter input */
.searchInput {
  border: 0.1px solid;
  color: black;
  width: 350px;
}


/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: rgba(50, 141, 30, 0.509);
}



input:focus + .slider {
  box-shadow: 0 0 1px rgba(50, 141, 30, 0.509);
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-container-label{
  margin-top: 8px;
}

.toggle-container{
    display: flex;
    flex-flow: wrap-reverse;
    justify-content: space-evenly;
    padding: 8px;
    border: 1px solid;
    border-color: lightgray;
    margin-bottom: 8px;
}


/* For selects */
.ng-select::ng-deep .ng-dropdown-panel-items{
  opacity: 1;
  max-height: 100px;
  overflow-y: scroll;
  background-color: white;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  border-radius: 5px;
  left: -12px;
  top: 5px;
  padding-bottom: 5px;
  padding-left: 11px;
}


/* For scroll in ng-select bar */
.ng-select::ng-deep::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;
}

.ng-select::ng-deep::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ng-select::ng-deep::-webkit-scrollbar-thumb {
  background: #888;
}

.ng-select::ng-deep::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* For dropbox of ng-select */
.ng-dropdown-panel .ng-dropdown-panel-items{
  max-height: 100px!important;
}

.ng-dropdown-panel{
  padding-left: 0.75rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-right: 0px;
}
